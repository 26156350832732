@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@900&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Estilos padrão para a barra de rolagem */
::-webkit-scrollbar {
  width: 4px; /* Largura da barra de rolagem vertical */
  height: 8px; /* Altura da barra de rolagem horizontal */
}

/* Estilo da trilha de rolagem (background) */
::-webkit-scrollbar-track {
  background: #f1f1f100; /* Cor da trilha */
}

/* Estilo da barra de rolagem (thumb) */
::-webkit-scrollbar-thumb {
  background: #888888a4; /* Cor da barra */
  border-radius: 4px; /* Borda arredondada */
}

/* Estilo da barra de rolagem quando o mouse passa por cima */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Cor da barra ao passar o mouse */
}

:root {
  --cor-principal: #337e71;
  --cor-secundaria: #68ad67;
  --cor-atenuacao: #bfbebd;
  --fundo_chat: #bdbab3;
  --cor-texto: #000000;
  --cor-texto_2: #ffffff;
  --chat_cor_1: #bfbebd;
  --chat_cor_2: #5ca55d;
  --chat_cor_3: #3B3B3B;
}


.bg-cor_principal {
  background-color: var(--cor-principal);
}

.bg-cor_secundaria {
  background-color: var(--cor-secundaria);
}

.bg-fundo_chat {
  background-color: var(--cor-fundo_chat);
}

.bg-chat_cor_1  {
  background-color: var(--chat_cor_1);
}

.bg_chat_tick {
  border-right-color: var(--chat_cor_1);
}

.bg-chat_cor_2 {
  background-color: var(--chat_cor_2);
}

.bg_chat_tick_2 {
  border-left-color: var(--chat_cor_2);
}

.bg-chat_cor_3 {
  background-color: var(--chat_cor_3);
}

.text-cor_1 {
  color: var(--cor-texto);
}

.text-cor_2 {
  color: var(--cor-texto_2);
}

.cropper-view-box {
  border-radius: 50%;
}

.border-cor_principal {
  border-color: var(--cor-principal);
}
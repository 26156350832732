@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
  
.fade-in {
  animation: fadeIn 1s ease-in;
}

.sombra {
  box-shadow: 0 5px 0px rgba(0, 0, 0, 0.65);
}